<template>
  <div class="row">
    <div class="col-lg-7">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.step1') }}</div>
          <small class="text-muted">{{ $t('general.basicParameters') }}</small>
          <h6 class="mt-2">{{ $t('hotspot.wizardGeneral') }}</h6>

          <div class="form-group mt-h">
            <label for="name">{{ $t('general.name') }}</label>
            <input
              type="text"
              name="name"
              id="name"
              :placeholder="$t('hotspot.wizardNamePlaceholder')"
              class="form-control"
              v-validate="'required|max:32'"
              :class="{ input: true, 'is-danger': errors.has('name') }"
              v-model="newHotspotData.name"
            />
            <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
          </div>

          <div class="form-group mt-h">
            <label for="hessid">{{ $t('hotspot.hessid') }}</label>
            <input
              type="text"
              name="hessid"
              id="hessid"
              placeholder="A3:E5:21:DA:FC:3F"
              class="form-control"
              v-validate="'mac'"
              :class="{ input: true, 'is-danger': errors.has('hessid') }"
              v-model="newHotspotData.hessid"
            />
            <span v-show="errors.has('hessid')" class="help is-danger">{{ errors.first('hessid') }}</span>
          </div>

          <div class="form-group">
            <label for="location">{{ $t('general.location') }}</label>
            <select v-model="newHotspotData.base_location" name="location" id="location" class="form-control">
              <option v-for="location in locationsList" :key="location.id" :value="location.id">
                {{ location.name }}
              </option>
            </select>
          </div>

          <Network
            :hotspotData="newHotspotData"
            :hotspotService="hotspotService"
            :commonService="commonService"
          ></Network>

          <div class="mt-1">
            <button
              type="button"
              class="btn btn-outline-primary float-right cpe-register-next-button"
              @click="setNewHotspotData"
              :disabled="errors.any()"
            >
              {{ $t('general.next') }}
            </button>

            <button
              type="button"
              class="btn btn-outline-secondary float-left cpe-register-back-button"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonService from '../../services/commonService';
import hotspotService from '../../services/hotspotService';
import Network from '../../components/Hotspots/Network';
import helpers from '../../helpers';

export default {
  name: 'HotspotStep1',
  components: { Network },

  data() {
    return {
      newHotspotData: {
        name: '',
        net_type: 1,
        internet: false,
        asra: false,
        esr: false,
        uesa: false,
        dgaf: false,
        venue: {
          group: 0,
          type: 0,
          names: []
        },
        ip_types: {
          ipv4type: -1,
          ipv6type: -1
        }
      }
    };
  },
  computed: {
    commonService() {
      return commonService;
    },
    hotspotService() {
      return hotspotService;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    }
  },
  methods: {
    setNewHotspotData() {
      this.$validator
        .validateAll({
          name: this.newHotspotData.name
        })
        .then(
          (result) => {
            if (result) {
              this.$store.commit('setNewHotspotData', this.newHotspotData);
              this.$parent.nextStep();
            }
          },
          () => {
            console.warn('Validation failed');
          }
        );
    }
  },
  created() {
    this.newHotspotData.base_location = this.$store.state.userData.base_location;
    if (this.$store.state.newHotspotData.name) {
      this.newHotspotData = this.$store.state.newHotspotData;
    }
  }
};
</script>
<style lang="scss" scoped></style>
