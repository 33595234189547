<template>
  <div class="row">
    <div class="col-lg-7">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.step2') }}</div>
          <small class="text-muted">{{ $t('general.advancedParameters') }}</small>

          <Realm :hotspotData="newHotspotData" :hotspotService="hotspotService" :commonService="commonService"></Realm>

          <div class="mt-1">
            <button
              type="button"
              class="btn btn-outline-primary float-right cpe-register-next-button"
              @click="setNewHotspotData"
              :disabled="errors.any()"
            >
              {{ $t('general.next') }}
            </button>

            <button
              type="button"
              class="btn btn-outline-secondary float-left cpe-register-back-button"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonService from '../../services/commonService';
import hotspotService from '../../services/hotspotService';
import Modal from '../../components/Modal.vue';
import Realm from '../../components/Hotspots/Realm.vue';

export default {
  components: { Modal, Realm },
  name: 'HotspotStep2',
  data() {
    return {
      newHotspotData: {
        realms: []
      },
      currentRealm: {
        name: '',
        eap: 13,
        auth: []
      },
      currentAuth: {
        type: 2,
        subtype: 1
      },
      editRealmIndex: null,
      isModalShow: false,
      isEditRealm: false
    };
  },
  computed: {
    commonService() {
      return commonService;
    },
    hotspotService() {
      return hotspotService;
    },
    locationsList() {
      return this.$store.state.locationsList;
    }
  },
  methods: {
    setNewHotspotData() {
      this.$validator.validateAll({}).then(
        (result) => {
          if (result) {
            this.$store.commit('setNewHotspotData', this.newHotspotData);
            this.$parent.nextStep();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    }
  },
  created() {
    this.newHotspotData = this.$store.state.newHotspotData;
  }
};
</script>
<style lang="scss" scoped>
.url-input-double {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;

  padding-right: 15px;
  padding-left: 15px;

  & .form-group {
    flex-grow: 1;
  }
}
</style>
