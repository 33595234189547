<template>
  <div class="row">
    <div class="col-lg-7">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.step4') }}</div>
          <small class="text-muted">{{ $t('general.summaryConfirmation') }}</small>
          <p class="mt-1">
            {{ $t('general.name') }}:
            <span class="text-muted">{{ newHotspotData.name }}</span>
          </p>
          <p class="mt-1">
            {{ $t('hotspot.hessid') }}:
            <span class="text-muted">{{ newHotspotData.hessid }}</span>
          </p>

          <h6>{{ $t('hotspot.network') }}</h6>
          <p class="mt-1">
            {{ $t('hotspot.internet') }}:
            <span class="text-muted">
              {{ newHotspotData.internet ? `${$t('general.yes')}` : `${$t('general.no')}` }}
            </span>
          </p>
          <p class="mt-1">
            {{ $t('hotspot.additionalStep') }}:
            <span class="text-muted">{{ newHotspotData.asra ? `${$t('general.yes')}` : `${$t('general.no')}` }}</span>
          </p>
          <p class="mt-1">
            {{ $t('hotspot.emergencyServices') }}:
            <span class="text-muted">{{ newHotspotData.esr ? `${$t('general.yes')}` : `${$t('general.no')}` }}</span>
          </p>
          <p class="mt-1">
            {{ $t('hotspot.emergencyServices') }}:
            <span class="text-muted">{{ newHotspotData.uesa ? `${$t('general.yes')}` : `${$t('general.no')}` }}</span>
          </p>
          <h6>{{ $t('hotspot.venue') }}</h6>
          <p class="mb-q">
            {{ $t('hotspot.group') }}:
            <span class="text-muted">{{ hotspotService.VENUE_GROUP[newHotspotData.venue.group].name }}</span>
          </p>
          <p class="mb-q">
            {{ $t('hotspot.type') }}:
            <span class="text-muted">
              {{ hotspotService.VENUE_GROUP[newHotspotData.venue.group].types[newHotspotData.venue.type].name }}
            </span>
          </p>
          <ul class="pl-2">
            <li v-for="venueName in newHotspotData.venue.names" class="ml-1">
              <p class="mb-0">
               {{ $t('general.name') }}:
                <span class="text-muted">{{ venueName.name }}</span>
              </p>
              <p class="mb-0">
                {{ $t('hotspot.language') }}:
                <span class="text-muted">{{ getLangNameWithI18nById(venueName.lang) }}</span>
              </p>
            </li>
          </ul>

          <h6>{{ $t('hotspot.naiRealmList') }}</h6>
          <ul class="pl-2">
            <li v-for="realm in newHotspotData.realms" class="ml-1">
              <p class="mb-0">
                {{ $t('general.name') }}:
                <span class="text-muted">{{ realm.name }}</span>
              </p>
              <p class="mb-0">
                {{ $t('hotspot.eapMethod') }}:
                <span class="text-muted">{{ hotspotService.EAP_METHODS[realm.eap].name }}</span>
              </p>
              <p class="mb-q">Auth:</p>
              <ul сlass="mt-0">
                <li v-for="auth in realm.auth">
                  <p class="mb-0">
                    {{ $t('hotspot.authType') }}:
                    <span class="text-muted">{{ hotspotService.EAP_TYPE[auth.type].name }}</span>
                  </p>
                  <p class="mb-0">
                    {{ $t('hotspot.authSubType') }}:
                    <span class="text-muted">{{ hotspotService.EAP_TYPE[auth.type].subtype[auth.subtype].name }}</span>
                  </p>
                </li>
              </ul>
            </li>
          </ul>

          <h6>{{ $t('hotspot.domainList') }}</h6>
          <ul class="pl-2">
            <li v-for="domain in newHotspotData.domains" class="ml-1">
              <p class="mb-0">{{ domain }}</p>
            </li>
          </ul>

          <h6>{{ $t('hotspot.threeGPP') }}</h6>
          <ul class="pl-2">
            <li v-for="cellular in newHotspotData.cellular" class="ml-1">
              <p class="mb-0">
                {{ $t('general.name') }}:
                <span class="text-muted">{{ cellular.name }}</span>
              </p>
              <p class="mb-0">
                MCC:
                <span class="text-muted">{{ cellular.mcc }}</span>
              </p>
              <p class="mb-0">
                MNC:
                <span class="text-muted">{{ cellular.mnc }}</span>
              </p>
            </li>
          </ul>

          <h6>Consortiums</h6>
          <ul class="pl-2">
            <li v-for="consortium in newHotspotData.consortiums" class="ml-1">
              <p class="mb-0">
                {{ $t('general.name') }}:
                <span class="text-muted">{{ consortium.name }}</span>
              </p>
              <p class="mb-0">
                {{$t('hotspot.organizationID')}}:
                <span class="text-muted">{{ consortium.id }}</span>
              </p>
            </li>
          </ul>

          <div class="mt-1">
            <button
              type="button"
              class="btn btn-outline-primary float-right cpe-register-next-button"
              @click="confirmHotspotCreation"
              :disabled="errors.any()"
            >
              {{ $t('general.confirm') }}
            </button>

            <button
              type="button"
              class="btn btn-outline-secondary float-left cpe-register-back-button"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonService from '../../services/commonService';
import hotspotService from '../../services/hotspotService';

export default {
  name: 'HotspotStep4',
  data() {
    return {
      newHotspotData: {}
    };
  },
  computed: {
    commonService() {
      return commonService;
    },
    hotspotService() {
      return hotspotService;
    },
    locationsList() {
      return this.$store.state.locationsList;
    }
  },
  methods: {
    getLangNameWithI18nById(langId) {
      try {
        const langsList = JSON.parse(JSON.stringify(this.hotspotService.LANGUAGE))
        const langObj = langsList.find((lang) => {
          return lang.id === langId;
        })
        if (Object.keys(langObj).length && langObj.name) {
          return langObj.name;
        }
        return langId
      } catch (e) {
        console.log(e)
        return langId
      }
    },
    setNewHotspotData() {
      this.$validator.validateAll({}).then(
        (result) => {
          if (result) {
            this.$store.commit('setNewHotspotData', this.newHotspotData);
            this.$parent.nextStep();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    confirmHotspotCreation() {
      hotspotService.createHotspot(this);
    }
  },
  created() {
    this.newHotspotData = JSON.parse(JSON.stringify(this.$store.state.newHotspotData));
  }
};
</script>
<style lang="scss" scoped>
.url-input-double {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;

  padding-right: 15px;
  padding-left: 15px;

  & .form-group {
    flex-grow: 1;
  }
}
</style>
